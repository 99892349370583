import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`OK, so I've finally made my way back through the entire original Mass Effect trilogy and I'm delighted to say: it holds up. When I originally saw all the comparison videos between the old and new versions, I was not blown away by the updates. I'm still not really. It feels like a lot of the models got more plastic-y feeling and my Shepard had a weird angular head (which may have existed before, it's been too long). But none of the updates felt like they detracted from the experience. So what are we here to discuss then? The DLC. `}</p>
    <p>{`As a general rule, I do not buy DLC for any game, even if I like it. It's always felt to me like the developers should have just included that in the original game, but in this case Legendary Edition came with pretty much every DLC ever made, minus one if I recall correctly, and I played through a bunch of it. So how is it?`}</p>
    <h4>{`Bring Down the Sky (Mass Effect)`}</h4>
    <p>{`This one felt fine. It's early in the series so this feels more world building. The Batarians hate Humans and have no compunctions about killing 3 million Humans, so go and stop them. I don't remember the Batarians being too present in ME1 outside of a few codex entries. It wasn't until ME2 that we really interacted with them at all, so it's nice to see them getting a little bit of screen time here. What's nice here is that you can get off the asteroid, breaking your immersion that the asteroid will hit the planet in a few hours, and come back and clean up later. I rate this DLC: Inoffensive.`}</p>
    <h4>{`Project Overlord (Mass Effect 2)`}</h4>
    <p>{`Here is a great example of the starting the mission and not being able to end it until you're done, and I hate it. This felt like an excuse to apologize for the Mako (but I'm #teammako all the way), and sure the Hammerhead feels a little more intuitive, but I did not care for this open world design at all. The story takes a pretty long time to pull together and it's payoff didn't really evoke in me what I'm sure the devs were going for, and doesn't really advance the story any. It's just another instance of the game telling you just how bad Cerberus is. This gets a rating of: Infuriating.`}</p>
    <h4>{`Arrival (Mass Effect 2)`}</h4>
    <p>{`Okay, now here was the first good DLC. The Reapers are coming through an out of the way relay, `}<em parentName="p">{`soon`}</em>{`, and you have 2 hours to delay them for some indeterminate amount of time. This story was pretty great and ties well into the overall story of the Reaper invasion. While you cannot leave in the middle of it, it also doesn't overstay it's welcome like Overlord does. Furthermore, at the end of it all you get to make a pretty devastating choice that you absolutely will hear about in the next game. A++, would play again.`}</p>
    <h4>{`From the Ashes (Mass Effect 3)`}</h4>
    <p>{`Going in to this, this was the DLC I was most excited to try out. You get a whole new character who's a `}<em parentName="p">{`Prothean!`}</em>{` Javik gets some unique powers and I used him in every mission I could this play through. All in all, this was a nice addition. It was nice to see a little more backstory on the Protheans, and to watch Liara's disappointment that he failed to meet her expectations since all he knew growing up was the war with the Reapers. All-in-all this DLC was: Worth It.`}</p>
    <h4>{`Leviathan (Mass Effect 3)`}</h4>
    <p>{`Leviathan wins the award for my favorite DLC of the series. In it you get to learn the true origin of the Reapers and whom their form is based on, along with who the first Reaper was. This was great and each step in the quest line can be done at your convenience. My only complaint is that it does kind of ruin a little bit of your discussion with the entity at the end of the game, but if this isn't your first time through then you don't really miss out on much. I rate the DLC: A++, will play every time.`}</p>
    <h4>{`Omega (Mass Effect 3)`}</h4>
    <p>{`Help Aria take back Omega! I wasn't blown away by this one really. I didn't much care for Omega in ME2 so wasn't really invested in this, and obviously Aria is just a jerk the whole time through. They also take you away from the rest of your crew for the two or so hours it takes to complete, which I wasn't really a fan of. I may be mistaken, but I think this is also the first and only time you see a female Turian, so it's got that going for it. I rate this DLC: Meh.`}</p>
    <h4>{`Citadel (Mass Effect 3)`}</h4>
    <p>{`Here's my hottest hot take! This was by far my least favorite DLC. I've since gone and read reviews printed at the time of release and they all seem to be pretty glowing. "This gives you the ending you really deserved!" For me though, the archive mission just felt like they were trying too hard to do Oceans Eleven, and the constant dialog where everyone absolutely knows they're gonna make it through the mission no problem just made me wanna face palm the whole time. Worst of all, you're trying to enter one of the most secret parts of the archives, so there must truly be something super important there right? Wrong. Basically it's just a biometric scanner for Shepard. No great revelations, just busy work as you try to finish up this quest line that you once again cannot leave. Worst of all, there's an interminable house party at the end of the DLC. It really feels like the whole time they were banking on nostalgia and trying to have heart and they just missed it. That being said, as you're about to board the Normandy after all is said and done, the conversation you have with Joker finally hits home. You're right, Joker, it has been a hell of a ride. The best. Rating: Swing and a miss.`}</p>
    <h4>{`Final Thoughts`}</h4>
    <p>{`One last note on Mass Effect 3. I don't remember there being so many filler missions. Maybe it has do with how they rebalanced the galaxy readiness meter after taking out multiplayer, but man there were so many missions where you'd land on a planet, hold a point from an advancing hoard of Reapers/Cerberus/etc for five minutes and then take off and get 10 points for your readiness meter. That being said, Mass Effect 3 remains a great game regardless of how you feel about the RGB endings. Every other storyline ending is well done and impactful and a big payoff for having played the series. It really was, and remains, one of the best series and stories in the history of video gaming.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      